// hooks
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";

const Catalog = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 10;

  // state
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [uploadThumb, setUploadThum] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // api calling
  const {
    data: homeExperienceData,
    isLoading,
    refetch,
  } = useQuery(
    {
      queryKey: ["catalog", page],
      queryFn: () => getCatalog(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("catalog", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        reset({});
        setSelectedFile(null);
        setShowCreateModal(false);
        toast.success("Catalog Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.err, { duration: 5000 });
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("catalog/edit", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        updateForm.reset({});
        setSelectedFile(null);
        setShowEditModal(false);
        toast.success("Catalog Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("catalog", { data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Catalog Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { reset, handleSubmit, control } = useForm();
  const updateForm = useForm();

  // functions
  const getCatalog = async () => {
    try {
      const res = await Api().get(`/catalog?limit=${data_limit}&page=${page}`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  const create = (data) => {
    // image required
    if (!selectedFile) {
      toast.error("Image Required!", { duration: 4000 });
      return;
    }

    const formData = new FormData();

    formData.append("files", selectedFile);
    formData.append("order", data.order);
    formData.append("active_status", data.active_status);

    crateMutate(formData);
  };

  const update = (data) => {
    const formData = new FormData();

    if (selectedFile) {
      formData.append("files", selectedFile);
      delete data.images;
    } else {
      formData.append("images", JSON.stringify(data.images[0]));
    }

    formData.append("active_status", data.active_status);
    formData.append("catalog_id", data._id);
    formData.append("order", data.order);
    updateMutate(formData);
  };

  const showNewModal = () => {
    setUploadThum(null);
    reset({});
    setSelectedFile(null);
    setShowCreateModal(true);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      catalog_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.catalog_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      catalog_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = (data) => {
    const firstImage = data?.images[0];
    setUploadThum(firstImage.url);
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    let firstImage = rowData.images[0];

    return (
      <>
        <Image preview src={firstImage.url} alt={rowData.image} className="shadow-2" width="100" height="100" imageStyle={{ objectFit: "cover" }} />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Catalog</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={homeExperienceData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "15rem" }}></Column>
              <Column field="order" header="Order" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="active_status" header="Active Status" body={(data) => (data.active_status ? "Active" : "Inactive")} sortable headerStyle={{ width: "15%", minWidth: "5rem" }}></Column>
              <Column field="created_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create Image Catalog" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="order">Order</label>
            <Controller defaultValue={0} control={control} name="order" render={({ field }) => <InputNumber className={classNames("w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>

          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="field">
            <label htmlFor="name">Active Status</label> <br />
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Image Catalog" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Order</label>
            <Controller defaultValue={0} control={updateForm.control} name="order" render={({ field }) => <InputNumber className={classNames("w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          <div className="">
            <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
          </div>
          <div className="field">
            <label htmlFor="name">Active Status</label> <br />
            <Controller control={updateForm.control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected categories?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Catalog, comparisonFn);
