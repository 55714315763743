import moment from "moment";
import "moment/locale/id";

const TimeFormatter = (date, format) => {
  let idMoment = moment;

  idMoment.locale("id");
  return idMoment(date).format(format);
};

export default TimeFormatter;
