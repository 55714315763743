import Users from "./pages/Users/Users";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import AccountSetting from "./pages/v2/AccountSetting";
import UpdateUser from "./pages/Users/UpdateUser";
import Product from "./pages/Product/Product";
import ProductCreate from "./pages/Product/ProductCreate";
import ProductCategory from "./pages/ProductCategory/ProductCategory";
import ProductUpdate from "./pages/Product/ProductUpdate";
import ListOrder from "./pages/ListOrder/ListOrder";
import ListOrderUpdate from "./pages/ListOrder/ListOrderUpdate";
import Discount from "./pages/Discount/Discount";
import DiscountCreate from "./pages/Discount/DiscountCreate";
import DiscountUpdate from "./pages/Discount/DiscountUpdate";
import UserCart from "./pages/UserCart/UserCart";
import HomeBanner from "./pages/HomeBanner/HomeBanner";
import UserWishlist from "./pages/UserWishlist/UserWishlist";
import PaymentSetting from "./pages/PaymentSetting/PaymentSetting";
import Catalog from "./pages/Catalog/Catalog";
import UpdateHomePage from "./pages/HomePageSetting/UpdateHomePage";
import HeaderSetting from "./pages/Header/HeaderSetting";
import RequestPickup from "./pages/RequestPickup/RequestPickup";
import Courier from "./pages/Courier/Courier";
import MerchentAddress from "./pages/MerchentAddress";
import HeaderSettingCreate from "./pages/Header/HeaderSettingCreate";
import HeaderSettingUpdate from "./pages/Header/HeaderSettingUpdate";
import StaticPage from "./pages/StaticPage/StaticPage";
import StaticPageCreate from "./pages/StaticPage/StaticPageCreate";
import StaticPageUpdate from "./pages/StaticPage/StaticPageUpdate";
import EmailSetting from "./pages/EmailSetting/EmailSetting";
import EmailSettingCreate from "./pages/EmailSetting/EmailSettingCreate";
import EmailSettingUpdate from "./pages/EmailSetting/EmailSettingUpdate";
import CurrencyPage from "./pages/CurrencyPage";
import AboutImage from "./pages/AboutImage/AboutImage";
import About from "./pages/About/About";

const pagesList = [
  // new
  {
    route: "/dashboard/email-setting",
    component: EmailSetting,
    childs: [
      {
        route: "/dashboard/email-setting/create",
        component: EmailSettingCreate,
        access: "create",
      },
      {
        route: "/dashboard/email-setting/update/:id",
        component: EmailSettingUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/merchant-address",
    component: MerchentAddress,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/currency-setting",
    component: CurrencyPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-category",
    component: ProductCategory,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/courier",
    component: Courier,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/pickup-request",
    component: RequestPickup,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/header",
    component: HeaderSetting,
    childs: [
      {
        route: "/dashboard/header/create",
        component: HeaderSettingCreate,
        access: "create",
      },
      {
        route: "/dashboard/header/update/:id",
        component: HeaderSettingUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/static-page",
    component: StaticPage,
    childs: [
      {
        route: "/dashboard/static-page/create",
        component: StaticPageCreate,
        access: "create",
      },
      {
        route: "/dashboard/static-page/update/:id",
        component: StaticPageUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/about",
    component: About,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/about-images",
    component: AboutImage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/home-page",
    component: UpdateHomePage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/catalog",
    component: Catalog,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/payment",
    component: PaymentSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/wishlist",
    component: UserWishlist,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/home-banner",
    component: HomeBanner,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/user-cart",
    component: UserCart,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product",
    component: Product,
    childs: [
      {
        route: "/dashboard/product/create",
        component: ProductCreate,
        access: "create",
      },
      {
        route: "/dashboard/product/update/:id",
        component: ProductUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/list-of-order",
    component: ListOrder,
    childs: [
      {
        route: "/dashboard/list-of-order/update/:id",
        component: ListOrderUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/discount",
    component: Discount,
    childs: [
      {
        route: "/dashboard/discount/create",
        component: DiscountCreate,
        access: "create",
      },
      {
        route: "/dashboard/discount/update/:id",
        component: DiscountUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },

  // dashboard/details is hidden , i hide in login file
  {
    route: "/dashboard/account",
    component: AccountSetting,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/users",
    component: Users,
    childs: [
      {
        route: "/dashboard/users/update/:id",
        component: UpdateUser,
        access: "update",
      },
    ],
    hasChild: true,
  },
];

export default pagesList;
