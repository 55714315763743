import { Jodit } from "jodit-react";

const JodithJournalConfig = {
  readonly: false,
  askBeforePasteHTML: false,
  defaultActionOnPaste: "insert_only_text",
  placeholder: "Start typings...",
  defaultFontName: "",
  cleanHTML: {
    fillEmptyParagraph: true,
  },
  height: 280,
  style: {
    fontSize: "14px",
  },
  buttons: [...Jodit.defaultOptions.buttons],
  removeButtons: ["eraser", "about", "print", "file", "video", "superscript", "paste", "selectall", "cut", "copy", "copyformat", "classSpan", "spellcheck"],
  controls: {
    fontsize: {
      list: {
        14: "14",
        16: "16",
        18: "18",
        20: "20",
      },
    },
    color: {
      list: {
        red: "#ada",
      },
    },
    font: {
      list: {
        Karla: "Karla",
        "Kiwi Maru": "Kiwi Maru",
      },
    },
  },
  colors: {
    greyscale: ["#000000", "#444444", "#434343", "#666666", "#999999", "#B7B7B7", "#CCCCCC", "#D9D9D9", "#EFEFEF", "#F3F3F3", "#FFFFFF"],
    palette: ["#618292", "#93B0BE", "#A5C2D1", "#7B9A83", "#85A78E", "#B5CFBC", "#D07967", "#D58B7C", "#E3B1A7", "#E9CA6B", "#F0DA97", "#F7EBC8"],
  },
  uploader: { insertImageAsBase64URI: false },
};

export default JodithJournalConfig;
