import { Jodit } from "jodit-react";

const staticPageJodithConfig = {
  readonly: false,
  askBeforePasteHTML: false,
  defaultActionOnPaste: "insert_only_text",
  placeholder: "Start typings...",
  defaultFontName: "",
  cleanHTML: {
    fillEmptyParagraph: true,
  },
  height: 300,
  style: {
    // fontFamily: "Lato",
    // fontSize: "14px",
  },
  buttons: [...Jodit.defaultOptions.buttons],
  removeButtons: [
    "icon",
    "eraser",
    "find",
    "print",
    "paragraph",
    "hr",
    "video",
    "about",
    "copyformat",
    "symbols",
    "superscript",
    "subscript",
    "file",
    "classSpan",
    "copy",
    "paste",
    "cut",
    "selectall",
    "spellcheck",
    "table",
    "undo",
    "redo",
    "image",
    "brush",
    "code",
    "listitem",
    "preview",
    "source",
    "dots",
    "font",
    "lineHeight",
    "indent",
  ],
  controls: {
    font: {
      list: {
        "Space Grotesk,sans-serif": "Space Grotesk",
        "Vollkorn, serif": "Vollkorn",
      },
    },
  },
  uploader: { insertImageAsBase64URI: true },
};

export default staticPageJodithConfig;
