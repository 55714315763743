// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import { Dropdown } from "primereact/dropdown";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import priceFormatter from "../../utils/priceFormatter";

const ListOrderUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, reset } = useForm();

  // query
  const { data: transactionData } = useQuery(["transaction", id], () => getTransactionDetails());
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/transaction/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/list-of-order");
        toast.success("Transaction updated!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getTransactionDetails = async () => {
    try {
      const res = await Api().get("/transaction/detail/" + id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      let result = res.data.data;

      reset(result);
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onSubmit = (data) => {
    updateMutate({
      status: data.status,
      transaction_id: data._id,
    });
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Transaction
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
      <div className="col-12">
        <Toolbar className="mb-4 w-full" left={leftToolbar} />
      </div>
      <TabView className="col-12 p-0 field">
        <TabPanel header="Transactions">
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Transaction ID : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="transaction_id"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 ">
            <label htmlFor="category_id">Transaction Status : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="status"
                render={({ field }) => (
                  <Dropdown
                    ref={field.ref}
                    optionLabel="label"
                    optionValue="id"
                    value={field.value}
                    onBlur={field.onBlur}
                    options={TransactionStatus}
                    className={classNames("w-full capitalize")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    placeholder="Choose category"
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Transaction Date : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="created_at"
                render={({ field }) => <InputText disabled value={NewTimeFormatter(field.value)} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Transaction Address">
          <section className="w-full grid">
            <div className="col-12">
              <p className="font-bold uppercase">Shipping Address</p>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Province : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.province"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">District : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.district"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Urban : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.urban"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">City : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.city"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Postal Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.postal_code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Phone Number : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.phone_number"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Email : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.email"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Country : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.country"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12">
              <label htmlFor="name">Address : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.address"
                  render={({ field }) => <InputTextarea rows={4} disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>

            <div className="col-12">
              <p className="font-bold uppercase">Billing Address</p>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Province : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.province"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">District : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.district"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Urban : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.urban"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">City : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.city"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Postal Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.postal_code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Phone Number : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.phone_number"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Email : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.email"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Country : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.country"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12">
              <label htmlFor="name">Address : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.address"
                  render={({ field }) => <InputTextarea rows={4} disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
          </section>
        </TabPanel>
        <TabPanel header="Products">
          <DataTable value={transactionData?.detail}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image imageClassName="object-contain w-full h-full" preview className="w-full h-full" src={data.product.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name" sortable></Column>
            <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ") || "-"} sortable></Column>
            <Column field="qty" header="Qty" sortable></Column>
            <Column field="note" header="Engraving Note" body={(d) => (d.note.length ? d.note : "-")} sortable></Column>
            <Column field="total_price" header="Price" body={(d) => priceFormatter(d.total_price)} sortable></Column>
            <Column field="note_price" header="Engraving Price" body={(d) => (d.note_price ? priceFormatter(d.note_price) : "-")} sortable></Column>

            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Shipping">
          <div className="field col-12 ">
            <label htmlFor="category_id">Shipping Status : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.status"
                render={({ field }) => (
                  <Dropdown
                    disabled
                    ref={field.ref}
                    optionLabel="label"
                    optionValue="id"
                    value={field.value}
                    onBlur={field.onBlur}
                    options={TransactionStatus}
                    className={classNames("w-full capitalize")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    placeholder="Choose category"
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Courier : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.courier_id.name"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Service : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.type"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Shipping Price : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.shipping_price"
                render={({ field }) => <InputNumber disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div className="flex justify-content-center mt-4 w-full">
        <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
        <Link to="/dashboard/list-of-order">
          <Button type="button" label="Back" className=" p-button-secondary" />
        </Link>
      </div>
    </form>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ListOrderUpdate, comparisonFn);
