import { useQuery } from "react-query";
import UserApi from "../api/UserApi";

export const useGetUsers = () => {
  const getUser = async () => {
    const response = await UserApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };
  return useQuery("users", () => getUser());
};
