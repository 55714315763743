// hooks
import React from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { Controller, useForm } from "react-hook-form";

// components
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

// utils
import classNames from "classnames";
import Api from "../api/Api";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";

const CurrencyPage = ({ permissions }) => {
  // React Query
  const { refetch } = useQuery(["currency"], () => getCurrency(), { initialData: {} });

  const { isLoading: loadingCurrency, mutate: updateCurrency } = useMutation(async (data) => await Api().post("/organization/convert", data), {
    onSettled: (res, error) => {
      if (res.data.status !== 200) {
        return toast.error(res.data.message);
      }

      toast.success("Currency Updated");
      refetch();
    },
  });

  // hooks
  const { control, handleSubmit, reset } = useForm();

  // functions
  const getCurrency = async () => {
    try {
      const response = await Api().get(`/organization/` + process.env.REACT_APP_ORGANIZATION_ID);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      reset({ nominal: response.data.data.currency_convert });

      return response.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const changeUserDetailHandler = async (data) => {
    updateCurrency(data);
  };

  return (
    <div className="p-fluid formgrid grid card col-12 mx-auto">
      <div className="col-12">
        <Toolbar
          className="mb-4 py-4"
          left={() => (
            <div className="">
              <h1 className="text-xl uppercase p-0 m-0">Currency Setting</h1>
            </div>
          )}
        />
      </div>
      <div className="col-12">
        <form onSubmit={handleSubmit(changeUserDetailHandler)} className="p-fluid formgrid grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="nominal">Rupiah Currency :</label>
            <Controller defaultValue={0} control={control} name="nominal" render={({ field }) => <InputNumber className={classNames("w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="flex justify-content-center mt-5 col-12 gap-1">
            <div className="mr-4">
              <Button disabled={!permissions.update} loading={loadingCurrency} label="Save Changes" className="p-button-success " />
            </div>
            <div className="">
              <Link to="/dashboard/products">
                <Button type="button" label="Back To Dashboard" className=" p-button-secondary" />
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CurrencyPage;
