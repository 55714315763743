import classNames from "classnames";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toolbar } from "primereact/toolbar";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import Api from "../api/Api";

const MerchentAddress = () => {
  const { handleSubmit, control, formState, reset, getValues } = useForm();

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [distric, setDistric] = useState([]);
  const [urban, setUrban] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { data: value, refetch } = useQuery("merchant-address", async () => getMerchantDetails());
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/organization/address", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.success("Failed Update Address", { duration: 4000 });
      }

      if (response.data.status === 200) {
        refetch();
        toast.success("Address Updated", { duration: 4000 });
      } else {
        refetch();
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const getMerchantDetails = async () => {
    try {
      const res = await Api().get("/organization/" + process.env.REACT_APP_ORGANIZATION_ID);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data?.location || {};
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getAddressOption = async (payload, type) => {
    try {
      const response = await Api().post("/address", payload);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      // success
      const data = response.data.data;
      switch (type) {
        case "province":
          setProvince(data);
          break;
        case "city":
          setCity(data);
          break;
        case "distric":
          setDistric(data);
          break;
        case "urban":
          setUrban(data);
          break;
        case "postal_code":
          setPostalCode(data);
          break;
        default:
          toast.error("Address type not found");
          break;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const update = (data) => {
    let payload = {
      type: "origin",
      ...data,
    };

    updateMutate(payload);
  };

  useEffect(() => {
    const editHandler = async (data) => {
      // get data
      await getAddressOption(
        {
          province: data.province,
        },
        "city"
      );
      await getAddressOption(
        {
          city: data.city,
        },
        "distric"
      );
      await getAddressOption(
        {
          district: data.district,
        },
        "urban"
      );
      await getAddressOption(
        {
          urban: data.urban,
          district: data.district,
        },
        "postal_code"
      );

      setLoading(false);
      reset({ ...data });
    };

    if (value) {
      if (Object.keys(value).length) {
        editHandler(value);
      }
    }
  }, [value, reset]);

  useEffect(() => {
    getAddressOption(
      {
        all_province: true,
      },
      "province"
    );
  }, []);

  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Merchant Address
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <form style={{ borderRadius: 0 }} className="card grid col-12 mx-auto" onSubmit={handleSubmit(update)}>
      <div className="col-12">
        <Toolbar className="mb-4 w-full" left={leftToolbar} />
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="address">Address Name : </label>
        <div className="w-full">
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="name"
            render={({ field }) => <InputText clas disabled={isLoading} placeholder="input address name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames({ "p-invalid": formState.errors.name }, "w-full")} />}
          />
          {formState.errors.name && (
            <small id="address" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="email">Email : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          name="email"
          render={({ field }) => <InputText disabled={isLoading} placeholder="Input email" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="email" type="text" className={classNames({ "p-invalid": formState.errors.email }, "w-full")} />}
        />
        {formState.errors.email && (
          <small id="email" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="phone_number">Phone Number : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue={""}
          id="phone_number"
          name="phone_number"
          render={({ field }) => (
            <InputText
              placeholder="Input Phone number"
              value={field.value}
              onBlur={field.onBlur}
              disabled={isLoading}
              ref={field.ref}
              onChange={(e) => field.onChange(e)}
              id="phone_number"
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className={classNames({ "p-invalid": formState.errors.phone_number }, "w-full")}
            />
          )}
        />
        {formState.errors.phone_number && (
          <small id="phone_number" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="choose-kurir">Province :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="province"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={!province.length || isLoading}
              value={field.value}
              onBlur={field.onBlur}
              options={province}
              className={classNames({ "p-invalid": formState.errors.province }, "w-full")}
              onChange={(e) => {
                field.onChange(e);
                getAddressOption(
                  {
                    province: e.target.value,
                  },
                  "city"
                );
                reset({ ...getValues(), city: null, district: null, urban: null, postal_code: null });
              }}
              placeholder="Select Province"
            />
          )}
        />
        {formState.errors.province && (
          <small id="choose-kurir" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="choose-kurir">City :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="city"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={!city.length || isLoading}
              value={field.value}
              onBlur={field.onBlur}
              options={city}
              className={classNames(
                {
                  "p-invalid": formState.errors.city,
                },
                "w-full"
              )}
              onChange={(e) => {
                field.onChange(e);
                getAddressOption(
                  {
                    city: e.target.value,
                  },
                  "distric"
                );
                reset({ ...getValues(), district: null, urban: null, postal_code: null });
              }}
              placeholder="Select Kota"
            />
          )}
        />
        {formState.errors.city && (
          <small id="choose-kurir" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="choose-kurir">District :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="district"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={!distric.length || isLoading}
              value={field.value}
              onBlur={field.onBlur}
              options={distric}
              className={classNames(
                {
                  "p-invalid": formState.errors.district,
                },
                "w-full"
              )}
              onChange={(e) => {
                field.onChange(e);
                getAddressOption(
                  {
                    district: e.target.value,
                  },
                  "urban"
                );
                reset({ ...getValues(), urban: null, postal_code: null });
              }}
              placeholder="Select District"
            />
          )}
        />
        {formState.errors.district && (
          <small id="choose-kurir" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="choose-kurir">Urban :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="urban"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={!urban.length || isLoading}
              value={field.value}
              onBlur={field.onBlur}
              options={urban}
              className={classNames(
                {
                  "p-invalid": formState.errors.urban,
                },
                "w-full"
              )}
              onChange={(e) => {
                field.onChange(e);
                getAddressOption(
                  {
                    district: getValues().district,
                    urban: e.target.value,
                  },
                  "postal_code"
                );
                reset({ ...getValues(), postal_code: null });
              }}
              placeholder="Select urban"
            />
          )}
        />
        {formState.errors.urban && (
          <small id="choose-kurir" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="choose-kurir">Postal Code :</label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="postal_code"
          render={({ field }) => (
            <Dropdown
              ref={field.ref}
              disabled={!postalCode.length}
              value={field.value}
              onBlur={field.onBlur}
              options={postalCode}
              className={classNames(
                {
                  "p-invalid": formState.errors.postal_code,
                },
                "w-full"
              )}
              onChange={(e) => {
                field.onChange(e);
              }}
              placeholder="Select Postal Code"
            />
          )}
        />
        {formState.errors.postal_code && (
          <small id="choose-kurir" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="field col-12 lg:col-12">
        <label htmlFor="address">Full Address : </label>
        <Controller
          rules={{ required: true }}
          control={control}
          name="address"
          defaultValue={""}
          render={({ field }) => <InputTextarea disabled={isLoading} value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={classNames({ "p-invalid": formState.errors.address }, "w-full")} placeholder="input address" autoResize rows="3" cols="20" />}
        />
        {formState.errors.address && (
          <small id="choose-kurir" className="p-error block pt-1">
            field required
          </small>
        )}
      </div>
      <div className="flex justify-content-center mt-4 w-full">
        <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
        <Link to="/dashboard">
          <Button type="button" label="Back" className=" p-button-secondary" />
        </Link>
      </div>
    </form>
  );
};

export default MerchentAddress;
