// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

// config
import staticPageJodithConfig from "../../config/staticPageJodithConfig";

// api related
import JoditEditor from "jodit-react";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import Api from "../../api/Api";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const StaticPageUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, reset } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "content",
  });

  // query
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/static/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/static-page");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    data.static_page_id = id;
    updateMutate(data);
  };

  const getProductDetails = async () => {
    try {
      const res = await Api().get("/static/detail/" + id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      reset(res.data.data);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Static Pages
          </h4>
        </div>
      </React.Fragment>
    );
  };

  // lifecycle
  useEffect(() => {
    getProductDetails();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    append({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card col-12 mx-auto">
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid mb-5 ">
          <div className="field col-12 ">
            <label htmlFor="route">Page Title :</label>
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="page_title"
              render={({ field }) => <InputText placeholder="Input page title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />}
            />
          </div>
          <div className="field col-12 ">
            <label htmlFor="route">Route :</label>
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="route"
              render={({ field }) => <InputText placeholder="Input route" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />}
            />
          </div>
        </div>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className={classNames({
              "mt-5": index > 0,
            })}
          >
            <div className="p-fluid formgrid grid ">
              <div className="field col-10 ">
                <label htmlFor="user">Title :</label>
                <Controller control={control} defaultValue={""} name={`content.${index}.title`} render={({ field }) => <InputText placeholder="Input title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />} />
              </div>
              <div className="field col-12 md:col-2">
                <label className="block"></label> <br />
                <div className="actions">
                  <ConfirmPopup />
                  <div className="flex justify-content-center">
                    <Button
                      type="button"
                      onClick={(e) => {
                        confirmPopup({
                          target: e.currentTarget,
                          message: "Remove this field",
                          icon: "pi pi-exclamation-triangle",
                          accept: () => remove(index),
                        });
                      }}
                      className=" p-button-danger"
                      icon="pi pi-times block"
                      label="Remove Field"
                    />
                  </div>
                </div>
              </div>
              <div className="field col-12">
                <label htmlFor="user">Body :</label>
                <Controller
                  control={control}
                  name={`content.${index}.body`}
                  render={({ field }) => (
                    <JoditEditor
                      ref={field.ref}
                      value={field.value || ""}
                      config={staticPageJodithConfig}
                      tabIndex={1}
                      onBlur={(e) => {
                        field.onBlur(e);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
              <div className="field col-12 ">
                <label htmlFor="order">Order :</label>
                <Controller defaultValue="" control={control} name={`content.${index}.order`} render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              </div>
            </div>
          </div>
        ))}
        <section>
          <Button type="button" label="Add Field" onClick={() => append({})} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center mt-4">
          <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/static-page">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(StaticPageUpdate, comparisonFn);
