// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import ReactImageUploading from "react-images-uploading";
import { Image } from "primereact/image";
import { Dropdown } from "primereact/dropdown";

// utils
import classNames from "classnames";
import CryptoJS from "crypto-js";

// api related
import Api from "../../api/Api";
import { useState } from "react";
// import { DevTool } from "@hookform/devtools";

const UpdateHomePage = () => {
  const home_id = process.env.REACT_APP_HOME_ID;
  const [images, setImages] = useState([]);
  const [images4, setImages4] = useState([]);
  const [images4Mobile, setImages4Mobile] = useState([]);

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { refetch: refetchHomePage } = useQuery("home-page", () => getHomePageDetails());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { data: catDataParent } = useQuery("product-category-parent", () => getProductCategoryParent(), { initialData: [] });
  const { data: bannerOption } = useQuery("home-banner", () => getHomeBanner(), { initialData: [] });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/home/page/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        refetchHomePage();
        toast.success("Success updated!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getHomePageDetails = async () => {
    try {
      const res = await Api().get("/home/detail?home_id=" + home_id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      // mapping
      let banner = [];
      let section3_products = [];
      let temp_images = [];
      let temp_images4 = [];
      let temp_images4_mobile = [];
      let temp_collections = [];
      let result = res.data.data;

      result.banner.map((d) => banner.push(d._id));
      result.section3_products.map((d) => section3_products.push(d._id));
      result.section2_category = result.section2_category._id;

      if (result.section3_images.length) {
        for (let i = 0; i < result.section3_images.length; i++) {
          temp_images.push({ data_url: result.section3_images[i].url, file: result.section3_images[i] });
        }
      }

      if (result.section4_images.length) {
        for (let i = 0; i < result.section4_images.length; i++) {
          temp_images4.push({ data_url: result.section4_images[i].url, file: result.section4_images[i] });
        }
      }

      if (result.section4_images_mobile.length) {
        for (let i = 0; i < result.section4_images_mobile.length; i++) {
          temp_images4_mobile.push({ data_url: result.section4_images_mobile[i].url, file: result.section4_images_mobile[i] });
        }
      }

      if (result.section2_collection.length) {
        for (let i = 0; i < result.section2_collection.length; i++) {
          temp_collections.push(result.section2_collection[i]._id);
        }
      }

      result.section2_collection = temp_collections;
      setImages(temp_images);
      setImages4(temp_images4);
      setImages4Mobile(temp_images4_mobile);

      let token = CryptoJS.AES.decrypt(result.ig_token, process.env.REACT_APP_IG_SECRET).toString(CryptoJS.enc.Utf8);

      result.ig_token = token;
      reset({ ...result, banner, section3_products });
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProduct = async () => {
    try {
      const res = await Api().get("/product");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getHomeBanner = async () => {
    try {
      const res = await Api().get("/home-banner");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProductCategory = async () => {
    try {
      const res = await Api().get("category");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getProductCategoryParent = async () => {
    try {
      const res = await Api().get("/category?parent=true");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const onChangeImage4 = (imageList) => {
    setImages4(imageList);
  };

  const onChangeImage4Mobile = (imageList) => {
    setImages4Mobile(imageList);
  };

  const onSubmit = async (data) => {
    try {
      // update ig token
      const update_token = await Api().post("/home/credentials", { ig_token: data.ig_token });

      if (update_token.data.status !== 200) {
        toast.error("Failed to update ig token");
      }

      const formData = new FormData();
      let file_key = ["files_thumbnail", "files", "files_mobile"];

      // if data is array then its gonna looop
      let array_key = ["no"];

      data.home_id = home_id;
      data.section3_products = JSON.stringify(data.section3_products);
      data.banner = JSON.stringify(data.banner);
      data.section2_collection = JSON.stringify(data.section2_collection || []);

      if (images.length) {
        // prev images
        let temp_prev_images = [];

        // new images
        let temp_image = [];

        for (let i = 0; i < images.length; i++) {
          // this mean its a prev image
          if (images[i].data_url.includes("https")) {
            temp_prev_images.push(images[i].file);
          } else {
            temp_image.push(images[i].file);
          }
        }

        if (temp_prev_images.length) {
          data.section3_images = JSON.stringify(temp_prev_images);
        } else {
          delete data.section3_images;
        }

        if (temp_image.length) {
          data.files = temp_image;
        }
      } else {
        data.section3_images = JSON.stringify([]);
      }

      if (images4.length) {
        // prev images
        let temp_prev_images = [];

        // new images
        let temp_image = [];

        for (let i = 0; i < images4.length; i++) {
          // this mean its a prev image
          if (images4[i].data_url.includes("https")) {
            temp_prev_images.push(images4[i].file);
          } else {
            temp_image.push(images4[i].file);
          }
        }

        if (temp_prev_images.length) {
          data.section4_images = JSON.stringify(temp_prev_images);
        } else {
          delete data.section4_images;
        }

        if (temp_image.length) {
          data.files4 = temp_image[0];
        }
      } else {
        data.section4_images = JSON.stringify([]);
      }

      if (images4Mobile.length) {
        // prev images
        let temp_prev_images = [];

        // new images
        let temp_image = [];

        for (let i = 0; i < images4Mobile.length; i++) {
          // this mean its a prev image
          if (images4Mobile[i].data_url.includes("https")) {
            temp_prev_images.push(images4Mobile[i].file);
          } else {
            temp_image.push(images4Mobile[i].file);
          }
        }

        if (temp_prev_images.length) {
          data.section4_images_mobile = JSON.stringify(temp_prev_images);
        } else {
          delete data.section4_images_mobile;
        }

        if (temp_image.length) {
          data.files4_mobile = temp_image[0];
        }
      } else {
        data.section4_images_mobile = JSON.stringify([]);
      }

      if (!data.related?.length) {
        delete data.related;
      }

      for (const key in data) {
        let isFile = file_key.find((data) => data === key);
        let isArrayKey = array_key.find((data) => data === key);

        if (isFile && !isArrayKey) {
          formData.append(key, data[key][0]);
        }

        if (!isFile && isArrayKey) {
          for (let i = 0; i < data[isArrayKey].length; i++) {
            formData.append(key, data[isArrayKey][i]);
          }
        }

        if (!isFile && !isArrayKey) {
          formData.append(key, data[key]);
        }
      }

      crateMutate(formData);
    } catch (error) {
      toast.error("Failed to update home");
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Homepage Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0]?.url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  const bannerTemplateOption = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width="100" src={option?.images[0]?.url} />
        <div className="pl-4">{option.button_name}</div>
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 1</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Top Text : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="top_text"
              render={({ field }) => (
                <InputText
                  placeholder="Input top text"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.top_text,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.top_text && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Top Text URL : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="top_text_url"
              render={({ field }) => (
                <InputText
                  placeholder="Input top text url"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.top_text_url,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.top_text_url && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="banner">Home Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner"
              render={({ field }) => (
                <MultiSelect
                  itemTemplate={bannerTemplateOption}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!bannerOption?.length}
                  value={field.value}
                  options={bannerOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="button_name"
                  optionValue="_id"
                  placeholder="Select banner"
                  display="chip"
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 2</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_collection">Collections : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="section2_collection"
              render={({ field }) => (
                <MultiSelect className="w-full" name="asdasd" emptyFilterMessage="no data found" disabled={!catData?.length} value={field.value} options={catData} onChange={(e) => field.onChange(e)} optionLabel="name" optionValue="_id" placeholder="Select product" display="chip" />
              )}
            />
            {formState.errors?.section2_collection && (
              <small id="section2_collection" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_category">Category : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section2_category"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className={classNames({ "p-invalid": formState.errors?.section2_category }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!catDataParent?.length}
                  value={field.value}
                  options={catDataParent}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select category"
                  display="chip"
                />
              )}
            />
            {formState.errors?.section2_category && (
              <small id="section2_category" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 3</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              defaultValue={""}
              name="section3_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              defaultValue={""}
              name="section3_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section3_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section3_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section3_description && (
              <small id="section3_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section3_products">Product : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section3_products"
              render={({ field }) => (
                <MultiSelect
                  className="w-full"
                  itemTemplate={productSelectTemplate}
                  emptyFilterMessage="no data found"
                  disabled={!productOption?.length}
                  value={field.value}
                  options={productOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select product"
                  display="chip"
                />
              )}
            />
            {formState.errors?.section3_products && (
              <small id="section3_products" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images: </label>
          <ReactImageUploading style={{ width: "100%" }} value={images} onChange={onChangeImage} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="col-12">
          <p className="font-bold uppercase">Section 4</p>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Image: </label>
          <ReactImageUploading style={{ width: "100%" }} value={images4} onChange={onChangeImage4} maxNumber={1} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Image (Mobile): </label>
          <ReactImageUploading style={{ width: "100%" }} value={images4Mobile} onChange={onChangeImage4Mobile} maxNumber={1} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Instagram Token : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="ig_token" render={({ field }) => <InputText placeholder="Input token" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />} />
          </div>
        </div>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateHomePage, comparisonFn);
