// hooks
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
// import { DevTool } from "@hookform/devtools";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import PagesApi from "../../api/PagesApi";

// utils
import routeGroup from "../../assets/data/pages-group.json";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useGetPage } from "../../hook/page.hook";
import { useNavigate } from "react-router-dom";

const Page = ({ permissions }) => {
  const navigate = useNavigate();

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // api calling
  const { data: pagesData, isLoading, isError, isSuccess, refetch } = useGetPage();

  // query
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await PagesApi.Update(data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Page Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await PagesApi.Delete(data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Page Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const updateForm = useForm();

  // functions
  const update = (data) => {
    let payload = {
      page_id: data._id,
      name: data.name,
      group: data.group,
      icon: data.icon,
      route: data.route,
      order: data.order,
    };

    updateMutate(payload);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      page_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.page_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      page_id: [data._id],
    };

    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editCategory = (data) => {
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // life cycle
  useEffect(() => {
    if (!isLoading && isSuccess) {
      let temp_data = [];

      for (let i = 0; i < pagesData.length; i++) {
        temp_data.push(pagesData[i]);
      }

      setPages(temp_data);
    }
  }, [isLoading, pagesData, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/pages/create")} />}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCategory(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="">
          <h5 className="m-0">Manage Pages</h5>
          <span className="block mt-4">
            Font List{" "}
            <a href="https://fontawesome.com/search?o=r&m=free&s=solid" target="_blank" rel="noopener noreferrer">
              Click Me!
            </a>
          </span>
        </div>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={pages}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Page found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="group" header="Group" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="name" header="Page Name" sortable headerStyle={{ width: "15%", minWidth: "10rem" }}></Column>
              <Column field="route" header="Route Path" sortable headerStyle={{ width: "25%", minWidth: "10rem" }}></Column>
              <Column field="icon" header="Page Icon" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="order" header="Order" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* edit */}
      <Dialog visible={showEditModal} header="Edit Page" modal className="p-fluid modal-container" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Route Name</label>
            <InputText placeholder="Input route name" {...updateForm.register("name", { required: true })} id="name" type="text" className={updateForm.formState.errors.name && "p-invalid"} />
            {updateForm.formState.errors.name && (
              <small id="name-help" className="p-error block">
                Route Name
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="route">Route Path</label>
            <InputText placeholder="example : dashboard/..." {...updateForm.register("route", { required: true })} id="route" type="text" className={updateForm.formState.errors.route && "p-invalid"} />
            {updateForm.formState.errors.route && (
              <small id="name-help" className="p-error block">
                Route Path Required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="icon">Icon</label>
            <InputText placeholder="icon" {...updateForm.register("icon", { required: true })} id="icon" type="text" className={updateForm.formState.errors.icon && "p-invalid"} />
            {updateForm.formState.errors.icon && (
              <small id="name-help" className="p-error block">
                Icon Required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="group">Order : </label>
            <Controller control={updateForm.control} name="order" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="field">
            <label htmlFor="group">Group : </label>
            <Controller control={updateForm.control} name="group" render={({ field }) => <Dropdown onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={routeGroup} optionLabel="name" placeholder="Route Group" />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Pages?</span>}
        </div>
      </Dialog>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Page, comparisonFn);
