// hooks
import React, { useState } from "react";
import { useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
// import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import priceFormatter from "../../utils/priceFormatter";

const UserWishlist = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 10;

  // state
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [expandedRows, setExpandedRows] = useState(null);
  // const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  // api calling
  const { data: wishlistData, isLoading } = useQuery(
    {
      queryKey: ["wishlist", page],
      queryFn: () => getUserWishlist(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // functions
  const getUserWishlist = async () => {
    try {
      const res = await Api().get(`/wishlist/group-user?limit=${data_limit}&page=${page}`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <DataTable value={rowData.items}>
          <Column
            body={(data) => (
              <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                <Image imageClassName="object-contain w-full h-full" preview className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
              </div>
            )}
            headerStyle={{ width: "150px", minWidth: "80px" }}
          ></Column>
          <Column field="product.name" header="Product Name"></Column>
          <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ") || "-"}></Column>
          <Column field="qty" header="Qty"></Column>
          <Column field="note" header="Engraving Note" body={(d) => (d.note?.length ? d.note : "-")}></Column>
          <Column field="product.price" header="Price" body={(d) => priceFormatter(d.product.price)}></Column>
          <Column field="note_price" header="Engraving Price" body={(d) => (d.note_price ? priceFormatter(d.note_price) : "-")}></Column>

          <Column headerStyle={{ width: "4rem" }}></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.product.length > 0;
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="">
          <h5 className="m-0">Manage User Wishlist</h5>
        </div>
        {/* <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span> */}
      </div>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <DataTable
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              loading={isLoading}
              value={wishlistData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id.user_id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              rowExpansionTemplate={rowExpansionTemplate}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // globalFilter={globalFilter}
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
              <Column field="user.name" header="User" headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
              {/* <Column field="bought" header="Bought"  body={(data) => (data.bought ? "Yes" : "No")} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column> */}
              <Column field="created_at" header="Created At" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "13rem" }}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(UserWishlist, comparisonFn);
