import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import Api from "../../api/Api";

const UpdateAddressModal = ({ show, setShow, refetch, value, user_id }) => {
  const { handleSubmit, control, formState, reset, getValues } = useForm();

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [distric, setDistric] = useState([]);
  const [urban, setUrban] = useState([]);
  const [postalCode, setPostalCode] = useState([]);

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/user/edit-address", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.success("Failed Update Address", { duration: 4000 });
      }

      if (response.data.status === 200) {
        refetch();
        setShow(false);
        toast.success("Address Updated", { duration: 4000 });
      } else {
        refetch();
        setShow(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const getAddressOption = async (payload, type) => {
    try {
      const response = await Api().post("/address", payload);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      // success
      const data = response.data.data;
      switch (type) {
        case "province":
          setProvince(data);
          break;
        case "city":
          setCity(data);
          break;
        case "distric":
          setDistric(data);
          break;
        case "urban":
          setUrban(data);
          break;
        case "postal_code":
          setPostalCode(data);
          break;
        default:
          toast.error("Address type not found");
          break;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const update = (data) => {
    let payload = {
      user_id: user_id,
      address_id: data._id,
      type: "destination",
      ...data,
    };

    updateMutate(payload);
  };

  useEffect(() => {
    const editHandler = async (data) => {
      // get data
      await getAddressOption(
        {
          province: data.province,
        },
        "city"
      );
      await getAddressOption(
        {
          city: data.city,
        },
        "distric"
      );
      await getAddressOption(
        {
          district: data.district,
        },
        "urban"
      );
      await getAddressOption(
        {
          urban: data.urban,
          district: data.district,
        },
        "postal_code"
      );

      reset({ ...data });
    };

    if (Object.keys(value).length) {
      editHandler(value);
    }
  }, [value, reset, setShow]);

  useEffect(() => {
    getAddressOption(
      {
        all_province: true,
      },
      "province"
    );
  }, []);

  return (
    <Dialog visible={show} header="Update Address" modal className="modal-container p-fluid" footer onHide={() => setShow(false)}>
      <form className="mt-2" onSubmit={handleSubmit(update)}>
        <div className="field">
          <label htmlFor="address">Address name : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="name"
            render={({ field }) => <InputText placeholder="Ex: My Home" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors.name && "p-invalid"} />}
          />
          {formState.errors.name && (
            <small id="address" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="email">Email : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            name="email"
            render={({ field }) => <InputText placeholder="Input email" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="email" type="text" className={formState.errors.email && "p-invalid"} />}
          />
          {formState.errors.email && (
            <small id="email" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="phone_number">Phone Number: </label>
          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={""}
            id="phone_number"
            name="phone_number"
            render={({ field }) => (
              <InputText
                placeholder="input phone number"
                value={field.value}
                onBlur={field.onBlur}
                ref={field.ref}
                onChange={(e) => field.onChange(e)}
                id="phone_number"
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={formState.errors.phone_number && "p-invalid"}
              />
            )}
          />
          {formState.errors.phone_number && (
            <small id="phone_number" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Province :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="province"
            render={({ field }) => (
              <Dropdown
                inputRef={field.ref}
                disabled={!province.length}
                value={field.value}
                onBlur={field.onBlur}
                options={province}
                className={classNames({
                  "p-invalid": formState.errors.province,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      province: e.target.value,
                    },
                    "city"
                  );
                  reset({ ...getValues(), city: null, district: null, urban: null, postal_code: null });
                }}
                placeholder="Select province"
              />
            )}
          />
          {formState.errors.province && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">City :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="city"
            render={({ field }) => (
              <Dropdown
                inputRef={field.ref}
                disabled={!city.length}
                value={field.value}
                onBlur={field.onBlur}
                options={city}
                className={classNames({
                  "p-invalid": formState.errors.city,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      city: e.target.value,
                    },
                    "distric"
                  );
                  reset({ ...getValues(), district: null, urban: null, postal_code: null });
                }}
                placeholder="select city"
              />
            )}
          />
          {formState.errors.city && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">District :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="district"
            render={({ field }) => (
              <Dropdown
                inputRef={field.ref}
                disabled={!distric.length}
                value={field.value}
                onBlur={field.onBlur}
                options={distric}
                className={classNames({
                  "p-invalid": formState.errors.district,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      district: e.target.value,
                    },
                    "urban"
                  );
                  reset({ ...getValues(), urban: null, postal_code: null });
                }}
                placeholder="select district"
              />
            )}
          />
          {formState.errors.district && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Sub District :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="urban"
            render={({ field }) => (
              <Dropdown
                inputRef={field.ref}
                disabled={!urban.length}
                value={field.value}
                onBlur={field.onBlur}
                options={urban}
                className={classNames({
                  "p-invalid": formState.errors.urban,
                })}
                onChange={(e) => {
                  field.onChange(e);
                  getAddressOption(
                    {
                      district: getValues().district,
                      urban: e.target.value,
                    },
                    "postal_code"
                  );
                  reset({ ...getValues(), postal_code: null });
                }}
                placeholder="select sub district"
              />
            )}
          />
          {formState.errors.urban && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="choose-kurir">Zip Code :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="postal_code"
            render={({ field }) => (
              <Dropdown
                inputRef={field.ref}
                disabled={!postalCode.length}
                value={field.value}
                onBlur={field.onBlur}
                options={postalCode}
                className={classNames({
                  "p-invalid": formState.errors.postal_code,
                })}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder="Select zipcode"
              />
            )}
          />
          {formState.errors.postal_code && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label htmlFor="address">Full Address : </label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="address"
            defaultValue={""}
            render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={formState.errors.address && "p-invalid"} placeholder="input full address" autoResize rows="3" cols="20" />}
          />
          {formState.errors.address && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field">
          <label className="block" htmlFor="active_status">
            Active status
          </label>
          <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
        </div>
        <div className="flex justify-content-end">
          <div className="flex">
            <Button type="button" onClick={() => setShow(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
            <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default UpdateAddressModal;
