// hooks
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { Image } from "primereact/image";
import { TabView, TabPanel } from "primereact/tabview";
import PDF from "../../components/PDF/PDF";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import { pdf } from "@react-pdf/renderer";
import priceFormatter from "../../utils/priceFormatter";

const RequestPickup = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 10;
  const buttonRequestMultipleEl = useRef(null);
  const [buttonRequestMultipleVisible, setButtonRequestMultipleVisible] = useState(false);

  // state
  const [pageNew, setPageNew] = React.useState(1);
  const [pageOnProgress, setPageOnProgress] = React.useState(1);
  const [pageDelivered, setPageDelivered] = React.useState(1);

  const [pageDatTableNew, setPageDataTableNew] = useState({ first: 0, rows: 10, page: 1 });
  const [pageDatTableOnProgress, setPageDataTableOnProgress] = useState({ first: 0, rows: 10, page: 1 });
  const [pageDatTableDelivered, setPageDataTableDelivered] = useState({ first: 0, rows: 10, page: 1 });

  const [pageInfoNew, setPageInfoNew] = useState({});
  const [pageInfoOnProgress, setPageInfoOnProgress] = useState({});
  const [pageInfoDelivered, setPageInfoDelivered] = useState({});

  const [expandedRows, setExpandedRows] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  // api calling
  const {
    data: requestPickupDataNew,
    isLoading: loadingRequestPickupDataNew,
    refetch: refetchRequestPickupDataNew,
  } = useQuery(
    {
      queryKey: ["pickup-request", "new", pageNew],
      queryFn: () => getTransactionsNew(pageNew),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  const {
    data: requestPickupDataOnProgress,
    isLoading: loadingRequestPickupDataOnProgress,
    refetch: refetchRequestPickupDataOnProgress,
  } = useQuery(
    {
      queryKey: ["pickup-request", "on-progress", pageOnProgress],
      queryFn: () => getTransactionsOnProgress(pageOnProgress),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  const {
    data: requestPickupDataDelivered,
    isLoading: loadingRequestPickupDataDelivered,
    refetch: refetchRequestPickupDataDelivered,
  } = useQuery(
    {
      queryKey: ["pickup-request", "delivered", pageDelivered],
      queryFn: () => getTransactionsDelivered(pageDelivered),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: loadingRequestPickup, mutate: requestPickupMutate } = useMutation(async (data) => await Api().post("/courier/pickup-request", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.success("Request Pickup Success", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: loadingRequestPickupMultiple, mutate: requestPickupMultipleMutate } = useMutation(async (data) => await Api().post("/courier/multiple-pickup-request", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.success("Request Pickup Success", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getTransactionsNew = async () => {
    try {
      const res = await Api().get(`/transaction/pickup-request?limit=${data_limit}&page=${pageNew}&status_shipping=0`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfoNew(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getPdfBlob = async (data) => {
    let temp = [];

    for (let i = 0; i < data.length; i++) {
      let total_weight = 0;

      for (let j = 0; j < data[i].detail.length; j++) {
        total_weight = total_weight + data[i].detail[j].product.weight;
      }

      temp.push({ ...data[i], total_weight: total_weight / 1000 });
    }

    let blobPdf = await pdf(PDF({ pdfData: temp })).toBlob();
    var fileURL = URL.createObjectURL(blobPdf);
    var a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    a.download = `FLAMAHR-${temp[0].resi}`;
    a.click();
  };

  const getTransactionsOnProgress = async () => {
    try {
      const res = await Api().get(`/transaction/pickup-request?limit=${data_limit}&page=${pageOnProgress}&status_shipping=2`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfoOnProgress(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const requestAllNewTransaction = () => {
    let temp_ids = [];

    for (let i = 0; i < selectItems.length; i++) {
      temp_ids.push(selectItems[i]._id);
    }

    requestPickupMultipleMutate({ transaction_ids: temp_ids });
  };

  const getTransactionsDelivered = async () => {
    try {
      const res = await Api().get(`/transaction/pickup-request?limit=${data_limit}&page=${pageDelivered}&status_shipping=3`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfoDelivered(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const pageHandlerNew = (data) => {
    setPageDataTableNew(data);
    setPageNew(data.page + 1);
  };

  const pageHandlerOnProgress = (data) => {
    setPageDataTableOnProgress(data);
    setPageOnProgress(data.page + 1);
  };

  const pageHandlerDelivered = (data) => {
    setPageDataTableDelivered(data);
    setPageDelivered(data.page + 1);
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <p>
          <span className="font-bold">Transaction Note</span>: {rowData?.note || "-"}
        </p>

        <DataTable value={rowData.detail}>
          <Column
            body={(data) => (
              <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                <Image imageClassName="object-contain w-full h-full" preview className="w-full h-full" src={data.product?.images[0]?.url} alt="image" />
              </div>
            )}
            headerStyle={{ width: "150px", minWidth: "80px" }}
          ></Column>
          <Column field="product.name" header="Product Name"></Column>
          <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ") || "-"}></Column>
          <Column field="qty" header="Qty"></Column>
          <Column field="note" header="Engraving Note" body={(d) => (d.note.length ? d.note : "-")}></Column>
          <Column field="total_price" header="Price" body={(d) => priceFormatter(d.total_price)}></Column>
          <Column field="note_price" header="Engraving Price" body={(d) => (d.note_price ? priceFormatter(d.note_price) : "-")}></Column>

          <Column headerStyle={{ width: "4rem" }}></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  const actionBodyTemplate = (rowData) => {
    const accept = () => {
      requestPickupMutate({ transaction_id: rowData._id });
    };

    return (
      <div className="actions">
        <ConfirmPopup />
        <div className="flex justify-content-center">
          <Button
            loading={loadingRequestPickup}
            onClick={(e) => {
              confirmPopup({
                target: e.currentTarget,
                message: "Request Pickup for " + rowData.transaction_id,
                icon: "pi pi-exclamation-triangle",
                accept,
              });
            }}
            icon="pi pi-car"
            label="Request"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <TabView className="card">
        <TabPanel header="New Transaction">
          <div className="grid crud-demo ">
            <div className="w-full">
              <div className="col-12">
                <ConfirmPopup target={buttonRequestMultipleEl.current} visible={buttonRequestMultipleVisible} onHide={() => setButtonRequestMultipleVisible(false)} message="Are you sure you want to proceed?" icon="pi pi-exclamation-triangle" accept={requestAllNewTransaction} />
                <Button ref={buttonRequestMultipleEl} loading={loadingRequestPickupMultiple} disabled={!selectItems.length} onClick={() => setButtonRequestMultipleVisible(true)} label={` Request Pickup (${selectItems.length})`} />
              </div>
              <div className=" col-12 mx-auto">
                <DataTable
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  loading={loadingRequestPickupDataNew}
                  value={requestPickupDataNew}
                  selection={selectItems}
                  onSelectionChange={(e) => setSelectItems(e.value)}
                  dataKey="_id"
                  paginator
                  rows={data_limit}
                  lazy
                  onPage={pageHandlerNew}
                  first={pageDatTableNew.first}
                  totalRecords={pageInfoNew.total_data}
                  rowExpansionTemplate={rowExpansionTemplate}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="No Data Found."
                  responsiveLayout="scroll"
                >
                  <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column field="user.name" header="User" headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
                  <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
                  <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "13rem" }}></Column>
                  <Column field="shipping_price" header="Shipping Price" body={(d) => priceFormatter(d.shipping_price)} headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
                  <Column field="shipping_price.shipping_info.insurance_price" header="Insurance" body={(d) => priceFormatter(d.shipping_info.insurance_price) || 0} headerStyle={{ width: "auto", minWidth: "8rem" }}></Column>
                  <Column field="total_amount" header="Total Amount" body={(d) => priceFormatter(d.total_amount)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                  {permissions.update || permissions.delete ? <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
                </DataTable>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="On Going">
          <div className="grid crud-demo ">
            <div className="w-full">
              <div className=" col-12 mx-auto">
                <DataTable
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  loading={loadingRequestPickupDataOnProgress}
                  value={requestPickupDataOnProgress}
                  selection={selectItems}
                  onSelectionChange={(e) => setSelectItems(e.value)}
                  dataKey="_id"
                  paginator
                  rows={data_limit}
                  lazy
                  onPage={pageHandlerOnProgress}
                  first={pageDatTableOnProgress.first}
                  totalRecords={pageInfoOnProgress.total_data}
                  rowExpansionTemplate={rowExpansionTemplate}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="No Data Found."
                  responsiveLayout="scroll"
                >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column
                    field="resi"
                    header="Resi"
                    body={(d) => (
                      <a className=" font-bold" target="_blank" rel="noopener noreferrer" href={"https://www.sicepat.com/checkAwb"}>
                        {d.resi}
                      </a>
                    )}
                    headerStyle={{ width: "auto", minWidth: "2rem" }}
                  ></Column>
                  <Column field="user.name" header="User" headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
                  <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
                  <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "13rem" }}></Column>
                  <Column field="shipping_price" header="Shipping" body={(d) => priceFormatter(d.shipping_price)} headerStyle={{ width: "10%", minWidth: "5rem" }}></Column>
                  <Column field="shipping_price.shipping_info.insurance_price" header="Insurance" body={(d) => priceFormatter(d.shipping_info.insurance_price) || 0} headerStyle={{ width: "auto", minWidth: "8rem" }}></Column>
                  <Column field="total_amount" header="Total Amount" body={(d) => priceFormatter(d.total_amount)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => <Button onClick={() => getPdfBlob([d])} icon="fas fa fa-solid fa-tags" label="Print Label" />} headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Delivered">
          <div className="grid crud-demo ">
            <div className="w-full">
              <div className=" col-12 mx-auto">
                <DataTable
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  loading={loadingRequestPickupDataDelivered}
                  value={requestPickupDataDelivered}
                  selection={selectItems}
                  onSelectionChange={(e) => setSelectItems(e.value)}
                  dataKey="_id"
                  paginator
                  rows={data_limit}
                  lazy
                  onPage={pageHandlerDelivered}
                  first={pageDatTableDelivered.first}
                  totalRecords={pageInfoDelivered.total_data}
                  rowExpansionTemplate={rowExpansionTemplate}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="No Data Found."
                  responsiveLayout="scroll"
                >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column
                    field="resi"
                    header="Resi"
                    body={(d) => (
                      <a className=" font-bold" target="_blank" rel="noopener noreferrer" href={"https://www.sicepat.com/checkAwb"}>
                        {d.resi}
                      </a>
                    )}
                    headerStyle={{ width: "auto", minWidth: "2rem" }}
                  ></Column>
                  <Column field="user.name" header="User" headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
                  <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
                  <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "13rem" }}></Column>
                  <Column field="shipping_price" header="Shipping Price" body={(d) => priceFormatter(d.shipping_price)} headerStyle={{ width: "10%", minWidth: "12rem" }}></Column>
                  <Column field="shipping_price.shipping_info.insurance_price" header="Insurance" body={(d) => priceFormatter(d.shipping_info.insurance_price) || 0} headerStyle={{ width: "auto", minWidth: "8rem" }}></Column>
                  <Column field="total_amount" header="Total Amount" body={(d) => priceFormatter(d.total_amount)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(RequestPickup, comparisonFn);
