// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import JoditEditor from "jodit-react";
import { Checkbox } from "primereact/checkbox";
import { AutoComplete } from "primereact/autocomplete";

// utils
import classNames from "classnames";
import staticPageJodithConfig from "../../config/staticPageJodithConfig";

// api related
import Api from "../../api/Api";
import { useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
// import { DevTool } from "@hookform/devtools";
import ReactImageUploading from "react-images-uploading";
import variantColorOptions from "../../assets/data/variant_color_options.json";
import variantSizeOptions from "../../assets/data/variant_size_options.json";
import Cookies from "js-cookie";

const variantTypeOptions = [
  { value: "Color", label: "Color" },
  { value: "Size", label: "Size" },
];

const ProductUpdate = () => {
  const currency = Cookies.get("currency");
  const { id } = useParams();
  const navigate = useNavigate();
  const [isVariant, setIsVariant] = useState(false);
  const [variantField, setVariantField] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [variantWatch, setVariantWatch] = useState([]);
  const [variantCombination, setVariantCombination] = useState([]);

  const [images, setImages] = useState([]);
  const [imageCustom, setImageCustom] = useState([]);

  // hooks
  const { control, handleSubmit, formState, reset, watch } = useForm();
  const using_note_watch = watch("using_note");
  const {
    fields: variantOneFields,
    remove: variantOneRemove,
    append: variantOneAppend,
  } = useFieldArray({
    control,
    name: "variant",
  });
  const variants_watch = useWatch({
    control,
    name: "variant",
  });

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/product/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/product");
        toast.success("Product updated!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getProductDetails = async () => {
    try {
      const res = await Api().get("/product/detail/" + id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      // mapping
      let result = res.data.data;
      let temp_related_ids = [];
      let temp_category_ids = [];
      let temp_variant = [];
      let temp_images = [];
      let temp_images_custom = [];
      let temp_watch_variant = [];

      if (result.related.length) {
        for (let index = 0; index < result.related.length; index++) {
          temp_related_ids.push(result.related[index]._id);
        }
      }

      if (result.category_id.length) {
        for (let index = 0; index < result.category_id.length; index++) {
          if (result.category_id[index]) {
            temp_category_ids.push(result.category_id[index]._id);
          }
        }
      }

      if (result.variant.length) {
        for (let i = 0; i < result.variant.length; i++) {
          temp_variant.push({ type: result.variant[i].name, value: result.variant[i].details });
          temp_watch_variant.push(result.variant[i].name);
        }
      }

      if (result.images.length) {
        for (let i = 0; i < result.images.length; i++) {
          temp_images.push({ data_url: result.images[i].url, file: result.images[i] });
        }
      }

      if (result.custom_images.length) {
        for (let i = 0; i < result.custom_images.length; i++) {
          temp_images_custom.push({ data_url: result.custom_images[i].url, file: result.custom_images[i] });
        }
      }

      result.variant = temp_variant;
      result.related = temp_related_ids;
      result.category_id = temp_category_ids;
      result.images = temp_images;

      setVariantWatch(temp_watch_variant);
      reset(result);
      setImages(temp_images);
      setImageCustom(temp_images_custom);
      setVariantCombination(result.variant_detail);
      setIsVariant(result.variant.length > 0);

      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const hanldeErrorImageUpload = (error) => {
    if (error?.maxFileSize) {
      toast.error("File Size Max 1MB");
    }
    if (error?.maxNumber) {
      toast.error("max file is 8 images");
    }
  };

  const getProduct = async () => {
    try {
      const res = await Api().get("/product");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProductCategory = async () => {
    try {
      const res = await Api().get("/category/option");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const onChangeImageCustom = (imageList) => {
    setImageCustom(imageList);
  };

  const searchColorOption = (event) => {
    let results;

    if (!event.query.trim().length) {
      results = [...variantColorOptions];
    } else {
      results = variantColorOptions.filter((color) => {
        return color.toLowerCase().startsWith(event.query.toLowerCase());
      });

      if (!variantColorOptions.find((color) => color.toLocaleLowerCase() === event.query.toLocaleLowerCase())) {
        results.push(event.query.charAt(0).toUpperCase() + event.query.slice(1));
      }
    }

    setColorOptions(results);
  };

  const searchSizeOption = (event) => {
    let results;

    if (!event.query.trim().length) {
      results = [...variantSizeOptions];
    } else {
      results = variantSizeOptions.filter((size) => {
        return size.toLowerCase().startsWith(event.query.toLowerCase());
      });

      if (!variantSizeOptions.find((size) => size.toLocaleLowerCase() === event.query.toLocaleLowerCase())) {
        results.push(event.query.charAt(0).toUpperCase() + event.query.slice(1));
      }
    }

    setSizeOptions(results);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_mobile"];

    if (!data.detail) {
      delete data.detail;
    }

    if (!data.description) {
      delete data.description;
    }

    // if data is array then its gonna looop
    let array_key = ["files"];

    data.related = JSON.stringify(data.related || []);
    data.category_id = JSON.stringify(data.category_id);
    data.product_id = id;
    data.custom_images = JSON.stringify([]);

    // to filter variant if has value if not remove
    data.variant = variantField.filter((v) => v.details.length > 0);

    if (variantField.length) {
      data.variant = JSON.stringify(data.variant);
      data.variant_detail = JSON.stringify(variantCombination);
    } else {
      data.variant = JSON.stringify([]);
      data.variant_detail = JSON.stringify([]);
    }

    if (!images.length) return toast.error("Please Upload Images");
    if (images.length) {
      // prev images
      let temp_prev_images = [];

      // new images
      let new_images = [];
      for (let i = 0; i < images.length; i++) {
        // this mean its a prev image
        if (images[i].data_url.includes("https")) {
          temp_prev_images.push(images[i].file);
        } else {
          new_images.push(images[i].file);
        }
      }

      if (temp_prev_images.length) {
        data.images = JSON.stringify(temp_prev_images);
      } else {
        delete data.images;
      }

      if (new_images.length) {
        data.files = new_images;
      }
    }

    // if (!imageCustom.length) return toast.error("Please Upload Image Size Guide");
    if (imageCustom.length) {
      // prev images
      let temp_prev_images = [];

      // new images
      let temp_image = [];

      for (let i = 0; i < imageCustom.length; i++) {
        // this mean its a prev image
        if (imageCustom[i].data_url.includes("https")) {
          temp_prev_images.push(imageCustom[i].file);
        } else {
          temp_image.push(imageCustom[i].file);
        }
      }

      if (temp_prev_images.length) {
        data.custom_images = JSON.stringify(temp_prev_images);
      } else {
        delete data.custom_images;
      }

      if (temp_image.length) {
        data.files_custom = temp_image[0];
      }
    }

    if (!data.related?.length) {
      delete data.related;
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }
      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }
    crateMutate(formData);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Product
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0].url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    getProductDetails();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (variants_watch) {
      // for variantWatch
      let temp_variantWatch = [];
      variants_watch.forEach((v) => {
        temp_variantWatch.push(v.type);
      });
      setVariantWatch(temp_variantWatch);

      let filtred_variant = variants_watch.filter((v) => v.type !== "" && v.value !== "");

      let color_variant = filtred_variant.find((v) => v.type === "Color")?.value || [];
      let size_variant = filtred_variant.find((v) => v.type === "Size")?.value || [];

      let first_array = color_variant.length >= size_variant.length ? color_variant : size_variant;
      let second_array = color_variant.length < size_variant.length ? color_variant : size_variant;

      // mapping for variant color
      let temp_variant_field_color = {
        name: "Color",
        details: [],
      };

      for (let i = 0; i < color_variant.length; i++) {
        temp_variant_field_color.details.push(color_variant[i]);
      }

      // mapping for variant size
      let temp_variant_field_size = {
        name: "Size",
        details: [],
      };

      for (let i = 0; i < size_variant.length; i++) {
        temp_variant_field_size.details.push(size_variant[i]);
      }

      setVariantField([temp_variant_field_color, temp_variant_field_size]);

      let array_comb = [];

      for (var i = 0; i < first_array.length; i++) {
        if (second_array.length) {
          for (var j = 0; j < second_array.length; j++) {
            array_comb.push({
              id: i + j * 120,
              variant: [first_array[i], second_array[j]],
              price: 0,
              qty: 0,
            });
          }
        } else {
          array_comb.push({
            id: i * 120,
            variant: [first_array[i]],
            price: 0,
            qty: 0,
          });
        }
      }

      setVariantCombination(array_comb);
    }
  }, [variants_watch]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Product Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="name"
              render={({ field }) => (
                <InputText
                  placeholder="Input product name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="sku">Product SKU : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="sku"
              render={({ field }) => (
                <InputText
                  placeholder="Input product sku"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.sku,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.sku && (
              <small id="sku" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-6">
          <label htmlFor="category_id">Category : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="category_id"
              rules={{ required: true }}
              render={({ field }) => (
                <MultiSelect
                  className={classNames({ "p-invalid": formState.errors?.category_id }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!catData?.length}
                  value={field.value}
                  options={catData}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select category"
                  display="chip"
                />
              )}
            />
            {formState.errors?.category_id && (
              <small id="category_id" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-6">
          <label htmlFor="related">Related Product : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="related"
              render={({ field }) => (
                <MultiSelect
                  itemTemplate={productSelectTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!productOption?.length}
                  value={field.value}
                  options={productOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select related product"
                  display="chip"
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="order">Order (Product position) : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="order" render={({ field }) => <InputNumber className={classNames("w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="meta_title">Product Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="meta_title">Show In Homepage : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="show_homepage" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="display_product">Display Product : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="display_product" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Product Pricing and other</p>
        </div>
        <div className="field col-12">
          <label htmlFor="price">
            Price <span style={{ textTransform: "uppercase" }}>({currency})</span> :{" "}
          </label>
          <Controller
            defaultValue={0}
            control={control}
            name="price"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />}
          />
          {formState.errors?.price && (
            <small id="price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        {using_note_watch ? (
          <div className="field col-12">
            <label htmlFor="note_price">
              Engraving Price <span style={{ textTransform: "uppercase" }}>({currency})</span> :{" "}
            </label>
            <Controller
              defaultValue={""}
              control={control}
              name="note_price"
              render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.note_price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons placeholder="0" />}
            />
            {formState.errors?.note_price && (
              <small id="note_price" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        ) : null}
        <div className="field col-12">
          <label htmlFor="meta_title">Use Engraving : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="using_note" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="qty">Qty : </label>
          <Controller
            defaultValue={""}
            control={control}
            name="qty"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.qty }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.qty && (
            <small id="qty" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="qty_alert">Qty Alert : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="qty_alert" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
            {formState.errors?.qty_alert && (
              <small id="qty_alert" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="weight">Weight (Gram) : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="weight" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
            {formState.errors?.weight && (
              <small id="weight" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Product Descriptions</p>
        </div>
        <div className="field col-12">
          <label htmlFor="user">Description :</label>
          <Controller
            rules={{ required: false }}
            control={control}
            name="description"
            render={({ field }) => (
              <JoditEditor
                ref={field.ref}
                value={field.value || ""}
                config={staticPageJodithConfig}
                tabIndex={1}
                onBlur={(e) => {
                  field.onBlur(e);
                }}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </div>
        <div className="field col-12">
          <label htmlFor="user">Detail :</label>
          <Controller
            rules={{ required: false }}
            control={control}
            name="detail"
            render={({ field }) => (
              <JoditEditor
                ref={field.ref}
                value={field.value || ""}
                config={staticPageJodithConfig}
                tabIndex={1}
                onBlur={(e) => {
                  field.onBlur(e);
                }}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images Max 1MB / Image: </label>
          <ReactImageUploading onError={hanldeErrorImageUpload} style={{ width: "100%" }} multiple value={images} onChange={onChangeImage} maxNumber={8} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Size Guide Image Max 1MB: </label>
          <ReactImageUploading onError={hanldeErrorImageUpload} style={{ width: "100%" }} value={imageCustom} onChange={onChangeImageCustom} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>

        {/* variants */}
        <div className="col-12 field flex align-items-center">
          <Checkbox
            onChange={(e) => {
              setIsVariant(e.checked);
            }}
            checked={isVariant}
          ></Checkbox>
          <p className="font-bold uppercase ml-2">Use Product Variants</p>
        </div>

        {isVariant ? (
          <>
            {/* variant input */}
            {variantOneFields.map((item, idx) => (
              <div key={item.id} className="field flex col-12 p-0">
                <div className="field col-4">
                  <label htmlFor={`variant[${idx}].type`}>Variant Type ({idx + 1}) : </label>
                  <div className="w-full">
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={""}
                      name={`variant[${idx}].type`}
                      render={({ field }) => (
                        <Dropdown
                          ref={field.ref}
                          optionLabel="label"
                          optionValue="value"
                          value={field.value}
                          onBlur={field.onBlur}
                          options={idx > 0 && variants_watch[0].type ? variantTypeOptions.filter((v) => v.value !== variants_watch[0].type) : variantTypeOptions}
                          className="w-full"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          placeholder="Select Variant"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-6">
                  <label htmlFor={`variant[${idx}].value`}>Value : </label>

                  <div className="w-full">
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={[]}
                      name={`variant[${idx}].value`}
                      render={({ field }) => (
                        <AutoComplete
                          multiple
                          inputId={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          suggestions={variantWatch[idx] === "Color" ? colorOptions : variantWatch[idx] === "Size" ? sizeOptions : null}
                          completeMethod={variantWatch[idx] === "Color" ? searchColorOption : variantWatch[idx] === "Size" ? searchSizeOption : null}
                          inputClassName="w-full"
                          placeholder="Input value"
                          className={classNames("w-full")}
                        />
                      )}
                    />
                  </div>
                  {/* <div className="w-full">
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={""}
                      name={`variant[${idx}].value`}
                      render={({ field }) => <InputText placeholder="Input variant value" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                    />
                  </div> */}
                </div>
                <div className="field col-2 mt-auto">
                  <Button type="button" className="p-button-danger w-full" icon="pi pi-trash" onClick={() => variantOneRemove(idx)} />
                </div>
              </div>
            ))}

            {variantWatch.length < 2 && (
              <div className="field col-12">
                <Button onClick={() => variantOneAppend("")} type="button" label="Add Variant" />
              </div>
            )}

            {/* variant table */}
            {variantCombination.length ? (
              <>
                <div className="col-12">
                  <p className="font-bold uppercase">Product Table</p>
                </div>
                <div style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }} className="field flex col-12 p-0 py-3 mb-5">
                  <div className="col-2">Variant</div>
                  <div className="col-5">Price</div>
                  <div className="col-5">Qty</div>
                </div>
                {variantCombination.map((item, idx) => (
                  <div key={idx * 120} className="field flex col-12 p-0">
                    <div className="field col-2">{item.variant.join(" - ") || "-"}</div>
                    <div className="field col-5">
                      <div className="w-full">
                        <InputNumber
                          className="w-full"
                          showButtons
                          placeholder="Input variant value depend on variant type"
                          value={item.price}
                          onChange={(e) => {
                            item.price = e.value;
                            setVariantCombination([...variantCombination]);
                          }}
                          id="name"
                        />
                      </div>
                    </div>
                    <div className="field col-5">
                      <div className="w-full">
                        <InputNumber
                          className="w-full"
                          showButtons
                          placeholder="Input variant value depend on variant type"
                          value={item.qty}
                          onChange={(e) => {
                            item.qty = e.value;
                            setVariantCombination([...variantCombination]);
                          }}
                          id="name"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </>
        ) : null}
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/product">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ProductUpdate, comparisonFn);
