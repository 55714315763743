// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import JoditEditor from "jodit-react";

// utils
import classNames from "classnames";
import emailJodithConfig from "../../config/emailJodithConfig";
import EmailTypeOptions from "../../assets/data/email_type.json";

// api related
import Api from "../../api/Api";
import { Dropdown } from "primereact/dropdown";

const EmailSettingCreate = () => {
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, formState, register } = useForm();
  const {
    fields: ccFields,
    remove: ccRemove,
    append: ccAppend,
  } = useFieldArray({
    control,
    name: "cc",
  });
  const {
    fields: bccFields,
    remove: bccRemove,
    append: bccAppend,
  } = useFieldArray({
    control,
    name: "bcc",
  });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("email", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/email-setting");
        toast.success("Email created!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    crateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Email
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    bccAppend("");
    ccAppend("");
  }, [bccAppend, ccAppend]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 ">
          <label htmlFor="choose-kurir">Type :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="type"
            render={({ field }) => (
              <Dropdown
                ref={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                options={EmailTypeOptions}
                optionLabel="label"
                optionValue="value"
                className={classNames({ "p-invalid": formState.errors.type }, "w-full")}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder="Select type"
              />
            )}
          />
          {formState.errors.type && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12 ">
          <label htmlFor="Subject">Subject : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="subject"
              render={({ field }) => (
                <InputText
                  placeholder="Input Subject"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.subject,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.subject && (
              <small id="Subject" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="body">Body : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="body"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={emailJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {formState.errors?.content && (
              <small id="content" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
          <p className="mt-2" style={{ color: "#b30000" }}>
            BODY NOTE : Every content that starts with symbol ‘$’ is a dynamic field so it automatically customised according to the desired content (for example $name = $Adinda which can be used to customised as customer name or email recipient)
          </p>
        </div>
        <div className="col-12">
          <label className="block mb-3" htmlFor="user">
            CC E-Mail (Optional) :
          </label>

          {ccFields.map((item, index) => (
            <div key={item.id}>
              <div className="p-fluid formgrid grid ">
                <div className="field col-10 ">
                  <InputText placeholder="Input email" {...register(`cc.${index}`)} id="name" type="text" />
                </div>
                <div className="field col-2 ">
                  <Button style={{ width: "100%" }} icon="pi pi-times block w-full" onClick={() => ccRemove(index)} className=" p-button-danger mr-4" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <section className="col-12" style={{ marginTop: "-15px" }}>
          <Button type="button" label="Add CC Email" onClick={() => ccAppend("")} className=" p-button-primary mr-4" />
        </section>
        <div className="col-12">
          <label className="block mb-3" htmlFor="user">
            BCC E-Mail (Optional) :
          </label>

          {bccFields.map((item, index) => (
            <div key={item.id}>
              <div className="p-fluid formgrid grid ">
                <div className="field col-10 ">
                  <InputText placeholder="Input email" {...register(`bcc.${index}`)} id="name" type="text" />
                </div>
                <div className="field col-2 ">
                  <Button style={{ width: "100%" }} icon="pi pi-times block w-full" onClick={() => bccRemove(index)} className=" p-button-danger mr-4" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <section className="col-12" style={{ marginTop: "-15px" }}>
          <Button type="button" label="Add BCC Email" onClick={() => bccAppend("")} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/email-setting">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(EmailSettingCreate, comparisonFn);
