// hooks
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
// import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../../api/Api";

// utils
import { useNavigate } from "react-router-dom";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import priceFormatter from "../../utils/priceFormatter";

const ListOrder = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 10;
  const navigate = useNavigate();

  // state
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [expandedRows, setExpandedRows] = useState(null);
  // const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);

  // api calling
  const {
    data: transactionData,
    isLoading,
    refetch,
  } = useQuery(
    {
      queryKey: ["transaction", page],
      queryFn: () => getTransactions(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/transaction", { data }), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Transaction Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getTransactions = async () => {
    try {
      const res = await Api().get(`/transaction?limit=${data_limit}&page=${page}`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      transaction_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.transaction_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      transaction_id: [data._id],
    };

    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <p>
          <span className="font-bold">Transaction Note</span>: {rowData?.note || "-"}
        </p>

        <DataTable value={rowData.detail}>
          <Column
            body={(data) => (
              <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
              </div>
            )}
            headerStyle={{ width: "150px", minWidth: "80px" }}
          ></Column>
          <Column field="product.name" header="Product Name"></Column>
          <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ") || "-"}></Column>
          <Column field="qty" header="Qty"></Column>
          <Column field="note" header="Engraving Note" body={(d) => (d.note.length ? d.note : "-")}></Column>
          <Column field="total_price" header="Price" body={(d) => priceFormatter(d.total_price)}></Column>
          <Column field="note_price" header="Engraving Price" body={(d) => (d.note_price ? priceFormatter(d.note_price) : "-")}></Column>

          <Column headerStyle={{ width: "4rem" }}></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {/* {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/product/create")} />} */}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/list-of-order/update/${rowData.transaction_id}`)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="">
          <h5 className="m-0">Manage Transactions</h5>
        </div>
        {/* <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span> */}
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              loading={isLoading}
              value={transactionData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              rowExpansionTemplate={rowExpansionTemplate}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              // globalFilter={globalFilter}
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
              <Column field="user.name" header="User" headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
              <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "13rem" }}></Column>
              <Column field="shipping_price" header="Shipping Price" body={(d) => priceFormatter(d.shipping_price)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="s" header="Insurance" body={(d) => (+d?.shipping_info?.insurance_price ? priceFormatter(d?.shipping_info?.insurance_price) : priceFormatter(0))} headerStyle={{ width: "auto", minWidth: "8rem" }}></Column>
              <Column field="total_amount" header="Total Amount" body={(d) => priceFormatter(d.total_amount)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Product?</span>}
        </div>
      </Dialog>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(ListOrder, comparisonFn);
