// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

// config
import staticPageJodithConfig from "../../config/staticPageJodithConfig";

// api related
import JoditEditor from "jodit-react";
import classNames from "classnames";
import Api from "../../api/Api";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";

const About = () => {
  // hooks
  const { control, handleSubmit, formState, watch, reset } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "contents",
  });

  const banner_watch = watch("banner");
  const banner_mobile_watch = watch("banner_mobile");

  // query
  const { data: aboutImagesData } = useQuery(["about-images"], async () => getAboutImages(), { initialData: [] });
  const { isLoading: updateIsLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/about/edit", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        toast.success("About Page Updated");
        getAboutDetails();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    data.about_id = data._id;

    updateMutate(data);
  };

  const getAboutDetails = async () => {
    try {
      const response = await Api().get("/about");
      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      let details = response.data.data;

      if (details.banner && details.banner.images.length) {
        details.banner = details.banner._id;
      }
      if (details.banner_mobile && details.banner_mobile.images.length) {
        details.banner_mobile = details.banner_mobile._id;
      }

      let temp_contents = [];

      for (let i = 0; i < details.contents.length; i++) {
        let content = details.contents[i];
        let temp_img = [];

        for (let j = 0; j < content.carousel_images.length; j++) {
          temp_img.push(content.carousel_images[j]._id);
        }

        temp_contents.push({ ...content, carousel_images: temp_img });
      }

      details.contents = temp_contents;
      reset(details);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAboutImages = async () => {
    try {
      const res = await Api().get(`/about/image`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  // components
  const imagesSelectOption = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={150} alt={option.name} src={option?.images[0].url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };
  const imagesSelectValue = (option) => {
    return (
      <div className="flex align-items-center">
        <Image height={40} alt={option?._id || ""} src={option?.images[0].url} preview />
      </div>
    );
  };
  const multipleImageSelected = (option) => {
    const search_selected_options = aboutImagesData.find((img) => img._id === option);
    return (
      <div className="flex align-items-center">
        <Image height={40} alt={search_selected_options?._id || ""} src={search_selected_options?.images[0].url} preview />
      </div>
    );
  };

  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            About Page
          </h4>
        </div>
      </React.Fragment>
    );
  };

  // lifecycle
  useEffect(() => {
    append({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAboutDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card col-12 mx-auto" style={{ borderRadius: 0 }}>
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid mb-5 ">
          <div className="field col-12">
            <label htmlFor="banner">Banner : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="banner"
                rules={{ required: false }}
                render={({ field }) => (
                  <Dropdown
                    className={classNames({ "p-invalid": formState.errors?.banner }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!aboutImagesData?.length}
                    value={field.value}
                    options={aboutImagesData}
                    itemTemplate={imagesSelectOption}
                    onChange={(e) => field.onChange(e)}
                    showClear
                    valueTemplate={banner_watch ? imagesSelectValue : null}
                    optionLabel="_id"
                    optionValue="_id"
                    placeholder="Select Images"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.banner && (
                <small id="banner" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12">
            <label htmlFor="banner_mobile">Banner Mobile : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="banner_mobile"
                rules={{ required: false }}
                render={({ field }) => (
                  <Dropdown
                    className={classNames({ "p-invalid": formState.errors?.banner_mobile }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!aboutImagesData?.length}
                    value={field.value}
                    options={aboutImagesData}
                    itemTemplate={imagesSelectOption}
                    onChange={(e) => field.onChange(e)}
                    showClear
                    valueTemplate={banner_mobile_watch ? imagesSelectValue : null}
                    optionLabel="_id"
                    optionValue="_id"
                    placeholder="Select Images"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.banner_mobile && (
                <small id="banner_mobile" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        </div>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className={classNames({
              "mt-5": index > 0,
            })}
          >
            <div className="p-fluid formgrid grid ">
              <div className="field col-10 ">
                <label htmlFor="user">Title :</label>
                <Controller
                  control={control}
                  name={`contents.${index}.title`}
                  render={({ field }) => (
                    <JoditEditor
                      ref={field.ref}
                      value={field.value || ""}
                      config={{ ...staticPageJodithConfig, height: 50 }}
                      tabIndex={1}
                      onBlur={(e) => {
                        field.onBlur(e);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label className="block"></label> <br />
                <div className="actions">
                  <ConfirmPopup />
                  <div className="flex justify-contents-center">
                    <Button
                      type="button"
                      onClick={(e) => {
                        confirmPopup({
                          target: e.currentTarget,
                          message: "Remove this field",
                          icon: "pi pi-exclamation-triangle",
                          accept: () => remove(index),
                        });
                      }}
                      className=" p-button-danger"
                      icon="pi pi-times block"
                      label="Remove Field"
                    />
                  </div>
                </div>
              </div>
              <div className="field col-12 ">
                <label htmlFor="user">Slug :</label>
                <Controller control={control} defaultValue={""} name={`contents.${index}.slug`} render={({ field }) => <InputText placeholder="Input slug" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />} />
              </div>
              <div className="field col-12">
                <label htmlFor="carousel_images">Carousel Image : </label>
                <div className="w-full">
                  <Controller
                    control={control}
                    name={`contents.${index}.carousel_images`}
                    render={({ field }) => (
                      <MultiSelect
                        className="w-full custom-image-value-multiselect"
                        emptyFilterMessage="no data found"
                        disabled={!aboutImagesData?.length}
                        value={field.value}
                        options={aboutImagesData}
                        itemTemplate={imagesSelectOption}
                        onChange={(e) => field.onChange(e)}
                        selectedItemTemplate={multipleImageSelected}
                        optionLabel="name"
                        optionValue="_id"
                        placeholder="Select images"
                        display="chip"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field col-12">
                <label htmlFor="user">Body :</label>
                <Controller
                  control={control}
                  name={`contents.${index}.content`}
                  render={({ field }) => (
                    <JoditEditor
                      ref={field.ref}
                      value={field.value || ""}
                      config={staticPageJodithConfig}
                      tabIndex={1}
                      onBlur={(e) => {
                        field.onBlur(e);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
              <div className="field col-12 ">
                <label htmlFor="email">Active Status</label>
                <Controller
                  control={control}
                  defaultValue={false}
                  name={`contents.${index}.active_status`}
                  render={({ field }) => (
                    <InputSwitch
                      className="block"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}
        <section>
          <Button type="button" label="Add Field" onClick={() => append({})} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center w-full mt-4">
          <Button label="Save" loading={updateIsLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/static-page">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(About, comparisonFn);
