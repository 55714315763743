import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import Api from "../api/Api";
import { Link } from "react-router-dom";
import priceFormatter from "../utils/priceFormatter";

const Dashboard = (props) => {
  const { data: dashboardData, isLoading } = useQuery("dashboard", () => getData("/dashboard/count"), { initialData: {} });

  const getData = async (endpointPath) => {
    try {
      const response = await Api().get(endpointPath);

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        throw new Error("an error occurred");
      }
      return response.data.data;
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  return (
    <div className="grid">
      <div className="col-12 md:col-6 lg:col-6">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block font-medium mb-3 text-lg uppercase">incomplete order new</span>
              <div className="text-900 flex flex-column" style={{ gap: "10px" }}>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.new?.amount}
                  <span className="pl-2 text-sm uppercase">amount</span>
                </div>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.new.qty}
                  <span className="pl-2 text-sm">QTY</span>
                </div>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="fas fa-solid fa-plus text-blue-500 text-xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 md:col-6 lg:col-6">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block mb-3 text-lg uppercase">incomplete order pending</span>
              <div className="text-900 flex flex-column" style={{ gap: "10px" }}>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.pending.amount}
                  <span className="pl-2 text-sm uppercase">amount</span>
                </div>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.pending.qty}
                  <span className="pl-2 text-sm">QTY</span>
                </div>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="fas fa-solid fa-clock-rotate-left text-orange-500 text-xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 ">
        <div className="card">
          <h5 className="uppercase font-semibold" style={{ fontSize: "14px" }}>
            registered user
          </h5>
          <div class="p-datatable p-component p-datatable-responsive-scroll">
            <div class="p-datatable-wrapper">
              <table class="p-datatable-table" role="table">
                <thead class="p-datatable-thead">
                  <tr role="row">
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 7 Days</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 14 Days</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 1 Month</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 1 Year</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                  <tr role="row" class="">
                    <td>{dashboardData.registered_user?.last_7}</td>
                    <td>{dashboardData.registered_user?.last_14}</td>
                    <td>{dashboardData.registered_user?.last_1m}</td>
                    <td>{dashboardData.registered_user?.last_1y}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 ">
        <div className="card">
          <h5 className="uppercase font-semibold" style={{ fontSize: "14px" }}>
            Order Total
          </h5>
          <div class="p-datatable p-component p-datatable-responsive-scroll">
            <div class="p-datatable-wrapper">
              <table class="p-datatable-table" role="table">
                <thead class="p-datatable-thead">
                  <tr role="row">
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Order status</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 7 Days</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 14 Days</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 1 Month</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 1 Year</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                  <tr role="row" class="">
                    <td className="uppercase font-bold">PENDING</td>
                    <td>{dashboardData.order_totals?.pending?.last_7}</td>
                    <td>{dashboardData.order_totals?.pending?.last_14}</td>
                    <td>{dashboardData.order_totals?.pending?.last_1m}</td>
                    <td>{dashboardData.order_totals?.pending?.last_1y}</td>
                  </tr>
                  <tr role="row" class="">
                    <td className="uppercase font-bold">PROCESSING</td>
                    <td>{dashboardData.order_totals?.processing?.last_7}</td>
                    <td>{dashboardData.order_totals?.processing?.last_14}</td>
                    <td>{dashboardData.order_totals?.processing?.last_1m}</td>
                    <td>{dashboardData.order_totals?.processing?.last_1y}</td>
                  </tr>
                  <tr role="row" class="">
                    <td className="uppercase font-bold">completed</td>
                    <td>{dashboardData.order_totals?.completed?.last_7}</td>
                    <td>{dashboardData.order_totals?.completed?.last_14}</td>
                    <td>{dashboardData.order_totals?.completed?.last_1m}</td>
                    <td>{dashboardData.order_totals?.completed?.last_1y}</td>
                  </tr>
                  <tr role="row" class="">
                    <td className="uppercase font-bold">cancelled</td>
                    <td>{dashboardData.order_totals?.cancelled?.last_7}</td>
                    <td>{dashboardData.order_totals?.cancelled?.last_14}</td>
                    <td>{dashboardData.order_totals?.cancelled?.last_1m}</td>
                    <td>{dashboardData.order_totals?.cancelled?.last_1y}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 ">
        <div className="card">
          <h5 className="uppercase font-semibold" style={{ fontSize: "14px" }}>
            most views product
          </h5>
          <DataTable loading={isLoading} value={dashboardData?.most_views_product} rows={3} responsiveLayout="scroll">
            <Column
              field="name"
              header="Poduct Name"
              body={(field) => (
                <Link to={`/dashboard/product/update/${field._id}`}>
                  <span>{field.name}</span>
                </Link>
              )}
              style={{ width: "auto" }}
            />
            <Column header="Price" body={(rowData) => priceFormatter(rowData?.price)} style={{ width: "150px" }} />
          </DataTable>
        </div>
      </div>
      <div className="col-12 ">
        <div className="card">
          <h5 className="uppercase font-semibold" style={{ fontSize: "14px" }}>
            BEST SELLER QTY
          </h5>
          <DataTable loading={isLoading} value={dashboardData?.best_seller_qty} rows={3} responsiveLayout="scroll">
            <Column
              field="name"
              header="Poduct Name"
              body={(field) => (
                <Link to={`/dashboard/product/update/${field._id}`}>
                  <span>{field.name}</span>
                </Link>
              )}
              style={{ width: "auto" }}
            />
            <Column header="qty" field="qty" />
            <Column header="Price" body={(rowData) => priceFormatter(rowData?.price)} style={{ width: "150px" }} />
            <Column header="Total" body={(rowData) => priceFormatter(rowData?.total)} style={{ width: "150px" }} />
          </DataTable>
        </div>
      </div>
      <div className="col-12 ">
        <div className="card">
          <h5 className="uppercase font-semibold" style={{ fontSize: "14px" }}>
            best seller amount
          </h5>
          <DataTable loading={isLoading} value={dashboardData?.best_seller_amount} rows={3} responsiveLayout="scroll">
            <Column
              field="name"
              header="Poduct Name"
              body={(field) => (
                <Link to={`/dashboard/product/update/${field._id}`}>
                  <span>{field.name}</span>
                </Link>
              )}
              style={{ width: "auto" }}
            />
            <Column header="qty" field="qty" />
            <Column header="Price" body={(rowData) => priceFormatter(rowData?.price)} style={{ width: "150px" }} />
            <Column header="Total" body={(rowData) => priceFormatter(rowData?.total)} style={{ width: "150px" }} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
